import {request} from '@/utils/axios'
// 智慧生产
// 企业详情
export const getCompanyDetail = id => {
    return request({
        method: 'get',
        url: `${sysUrl}/business/company/${id}`
    })
}

// 获取企业图片
export const getCompanyImage = id => {
    return request({
        method: 'get',
        url: `${sysUrl}/web/companyPhoto/list`,
        params: {
            companyId: id,
            pageSize: 1000
        }
    })
}

// 根据企业id获取设备数量
export const getDeviceCountByCompany = (id, headers) => {
    return request({
        method: 'get',
        url: `${thingsApi}/thingsapi//v1/api/dev/devTypes`,
        headers,
        params: {
            companyid: id
        }
    })
}

// 根据企业名称获取设备信息
export const getDeviceInfoByCompany = (params, headers) => {
    return request({
        method: 'get',
        url: `${thingsApi}/thingsapi/v2/api/dev/deviceAllBycompanyName`,
        headers,
        params
    })
}
// 根据企业名称获取设备信息
export const getOrgInfoList = (params, headers) => {
    return request({
        method: 'get',
        url: `${thingsApi}/vscreen-admin/business/orgInfo/getOrgInfoList`,
        headers,
        params
    })
}

//
// 根据企业id获取设备信息
export const getDeviceInfoByCompanyId = (params, headers) => {
    return request({
        method: 'get',
        url: `${thingsApi}/thingsapi//v1/api/dev/deviceAllBycompanyId`,
        headers,
        params
    })
}

// 获取设备历史数据
export const getDeviceOldData = (data, headers) => {
    return request({
        method: 'post',
        url: `${thingsApi}//thingsapi/v1/api/data/olddata`,
        headers,
        data
    })
}

// 获取设备实时数据
export const getDeviceRealData = (params, headers) => {
    return request({
        method: 'get',
        url: `${thingsApi}/thingsapi/v1/api/data/realdata`,
        headers,
        params
    })
}

// 区域获取设备信息
export const getDeviceByArea = (params, headers) => {
    return request({
        method: 'get',
        url: `${thingsApi}/thingsapi//v1/api/dev/deviceAllByArea`,
        headers,
        params
    })
}
